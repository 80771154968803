<template>
  <el-container class="main-container">
    <el-header class="header-container">
      <router-link to="/">
        <div class="logo">
          <img src="../assets/tqlogo.png" alt="Company Logo">
        </div>
      </router-link>

      <el-menu mode="horizontal" router :default-active="$route.path" text-color="#333" active-text-color="#409EFF"
        class="custom-menu">
        <el-menu-item class="custom-menu-item" index="/listens/list" @click="refresh">我的音乐</el-menu-item>
        <el-menu-item class="custom-menu-item" index="/my/profile" @click="refresh">个人中心</el-menu-item>
      </el-menu>
      <div class="header-personal">
        <el-dropdown style="text-align: right; font-size: 16px" class="el-dropdown-link">
          <i>
            <img v-if="headimgurl" :src="headimgurl" alt="avatar" class="avatar-icon">
          </i>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="usercenter">个人中心</el-dropdown-item>
            <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-header>
    <el-container class="content-container">
      <el-main class="main-content">
        <div v-if="$route.path === '/'">
          <h3 style="text-align: center;">欢迎 {{ loggedInUsername }} 来到 听清 · 网站 </h3>
          <div class="notice-detail" >
            <el-card class="box-card" v-if="notice">
              <h2 class="title">{{ notice.title }}</h2>
              <div class="info">
                <p class="author"> {{ notice.author }}</p>
                <p class="date"> {{ formatDate(notice.createdAt) }}</p>
              </div>
              <el-divider></el-divider>
              <div class="ql-editor">
                <p v-html="notice.content"></p>
              </div>
            </el-card>
          </div>
        </div>
        <router-view></router-view>
      </el-main>
    </el-container>

    <!-- 移动端 底部导航栏 -->
    <el-footer class="footer-menu" v-show="isMobile">
      <el-menu mode="horizontal" class="custom-menu2" router :default-active="$route.path" text-color="#333"
        active-text-color="#409EFF" size="small">
        <el-menu-item class="custom-menu-item" index="/listens/list" @click="refresh">我的音乐</el-menu-item>
        <el-menu-item class="custom-menu-item" index="/my/profile" @click="refresh">个人中心</el-menu-item>
      </el-menu>
    </el-footer>
  </el-container>
</template>


<script>
import router from '@/router';
import { VueEditor } from 'vue2-editor';


export default {
  data() {
    return {
      loggedInUsername: null, // 假设这个变量保存已登录用户的用户名
      headimgurl: null,
      isMobile: false,
      notice: {
        title: '',
        author: '',
        content: '',
        createdAt: '',
        sites: [],
      },
    };
  },
  components: {
    router,
    VueEditor,
  },
  methods: {
    async fetchUserInfo() {
      try {
        const token = localStorage.getItem('token');
        const response = await this.$http.get('/userinfo', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        this.userInfo = response.data.user;
        this.loggedInUsername = this.userInfo.nickname
        this.headimgurl = this.userInfo.headimgurl
        // console.log('2', this.userInfo.nickname)
      } catch (error) {
        if (error.response && error.response.status !== 401) {
          this.$message({
            type: 'error',
            message: '获取用户信息失败'
          });
        }
      }
    },

    async fetchNotice() {
      const id = 'tqing';
      try {
        const res = await this.$http.get(`/notices/detail/${id}`);
        this.notice = res.data;
      } catch (error) {
        // console.error('Error fetching notice:', error);
        // 你可以根据需要添加额外的错误处理逻辑，比如显示错误提示等
        this.notice = null; // 或者设置为一个默认值，避免`this.notice`为空时报错
      }
    },


    formatDate(date) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(date).toLocaleDateString('zh-CN', options);
    },

    logout() {
      // 清除本地存储中的令牌
      localStorage.removeItem('token');
      // 跳转到登录页
      this.$router.push('/login');
      this.$message({
        type: 'success',
        message: '退出登录成功',
      });
    },

    usercenter() {
      this.$router.push('/my/profile');
      // location.reload(); // 刷新页面
    },

    refresh() {
      this.fetchUserInfo()
      this.fetchNotice()
    },

    checkIsMobile() {
      this.isMobile = window.innerWidth <= 768;
    },

  },
  created() {
    this.refresh();
    this.checkIsMobile();
    window.addEventListener('resize', this.checkIsMobile);
  },
  mounted() {
    // 当组件挂载时获取已登录用户的用户名
    // this.fetchLoggedInUsername();
    this.refresh()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkIsMobile);
  }
};
</script>

<style>
.main-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  /* 使容器高度占满视口 */
  background-color: #f3f3f3;
}

.header-container {
  flex: 0 0 auto;
  /* 页头的高度固定 */
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
  /* 确保头部在其他内容之上 */
}

.content-container {
  flex: 1 1 auto;
  /* 中间内容区域占用剩余空间 */
  overflow-y: auto;
  /* 内容区域有滚动条 */
  /* margin-bottom: -50px; */
}


.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  /* margin-right: 20px; */
}

.logo img {
  height: 50px;
  width: auto;
}

.header-title {
  text-align: center;
  font-size: 18px;
}

.title {
  font-size: 20px;
  font-weight: bold;
  background-image: linear-gradient(to top, #c471f5 0%, #fa71cd 100%);
  -webkit-background-clip: text;
  background-clip: text;
  /* 添加标准的 background-clip 属性 */
  /* -webkit-text-fill-color: transparent; */
}

.header-content {
  display: flex;
  align-items: center;
}

.el-header {
  /* background-image: linear-gradient(to top, #C5CEFF 0%, #ffffff 70%); */
  background-color: #ffffff;
  color: #333;
  line-height: 60px;
}

.custom-menu {
  /* background-image: linear-gradient(to top, #C5CEFF 0%, #ffffff 70%) !important; */
  background-color: #ffffff;
  margin: 0px 30px;
}

.custom-menu-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
}

.header-personal {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 120px;
}


.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
}

.avatar-icon {
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  /* margin-right: 20px; */
  border-radius: 100%;
  width: 40px;
  height: 40px;
  border: 2px solid #ffffff;
  /* 设置边框颜色和宽度 */
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); 可选：添加阴影效果 */
}



.username {
  font-size: 20px;
  font-weight: bold;
  background: linear-gradient(to right, #d6ddfa, #6d85f3);
  -webkit-background-clip: text;
  background-clip: text;
  /* 添加标准的 background-clip 属性 */
  -webkit-text-fill-color: transparent;
}

/* .logout-btn {
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
} */



.el-main {
  padding: 20px;
}

.el-aside {
  /* background-image: linear-gradient(to bottom, #C5CEFF 0%, #ffffff 70%); */
  background-color: #ffffff;
  color: #333;
}

.footer-menu {
  display: none;
}


.tooltip-text {
  white-space: pre-wrap;

  /* 保留空白符，允许换行 */
  /* max-width: 200px; */
  /* 设置最大宽度 */
}

.text {
  font-size: 15px;
  margin: 5px;
  letter-spacing: 1px;
  line-height: 25px;
}

.text1 {
  font-weight: 600;
  color: #409EFF;
}

/* 通知 */
.notice-detail {
  padding: 0px 10px;
}

.title {
  text-align: center;
  margin-bottom: 10px;
}

.box-card {
  margin-top: 20px;
}

.info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  margin: 10px 30px;
}


.ql-editor {
  margin: 0px 20px;
}

.author,
.date {
  margin: 0;
}


/* 移动端样式 */
@media (max-width: 768px) {
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    /* 确保头部在其他内容之上 */
  }

  .el-container {
    padding: 30px 0px;
    /* 确保内容不被头部遮挡，根据头部的高度调整 */
  }

  /* .header-title {
    margin-bottom: 10px;
  } */

  /* .header-content {
    margin-top: 10px;
    flex-direction: column;
    align-items: center;
  } */

  .header-personal {
    display: flex;
    justify-content: right;
    align-items: center;
  }


  /* .avatar-icon {
    margin-right: 0;
    margin-bottom: 10px;
  } */

  /* .logout-btn {
    width: 100%;
    justify-content: center;
  } */

  .footer-menu {
    flex: 0 0 auto;
    /* 页脚的高度固定 */
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-image: linear-gradient(to bottom, #C5CEFF 0%, #ffffff 70%) !important; */
    background-color: #ffffff;
    /* margin-bottom: 20px; */
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    /* 确保底部在其他内容之上 */
  }

  .custom-menu2 {
    /* background-image: linear-gradient(to bottom, #C5CEFF 0%, #ffffff 70%) !important; */
    background-color: #ffffff;
  }

  .custom-menu {
    display: none;
  }
}
</style>
