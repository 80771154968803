import Vue from 'vue'
import App from './App.vue'
import './plugins/element.js'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import VueResponsive from 'vue-responsive'  // 引入 vue-responsive
import { QuillEditor } from 'vue-quill-editor';
import 'quill/dist/quill.snow.css'; // 引入 Quill 编辑器样式

Vue.component('quill-editor', QuillEditor);


Vue.use(ElementUI)
Vue.use(VueResponsive)  // 使用 vue-responsive

Vue.config.productionTip = false

import http from './http'
Vue.prototype.$http = http

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
