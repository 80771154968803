// 导入 Vue、VueRouter 模块
import Vue from 'vue'
import VueRouter from 'vue-router'

// 导入各个视图组件
import LoginView from '../views/LoginView.vue'
import MainView from '../views/MainView.vue'
import ListenList from '../views/ListenList.vue'
import ListenDetail from '../views/ListenDetail.vue'
import UserCenter from '../views/UserCenter.vue'
import NotFound from '../views/NotFound.vue'
import NoticeDetail from '../views/NoticeDetail.vue'
import WechatCallback from '../views/WechatCallback.vue'; // 引入WechatCallback组件


// 使用 VueRouter 插件
Vue.use(VueRouter)


// 定义路由规则
const routes = [
  // 登录页面路由规则，isPublic 表示是否为公共页面
  { path: '/login', name: 'login', component: LoginView, meta: { isPublic: true } },
  // 微信回调页面路由规则
  { path: '/wechat-callback', name: 'wechat-callback', component: WechatCallback, meta: { isPublic: true } },

  // 添加 catch-all 路由
  { path: '*', name: 'not-found', component: NotFound },

  // 主页面路由规则
  {
    path: '/',
    name: 'main',
    component: MainView,
    children: [
      { path: '/listens/list', component: ListenList },
      { path: '/listens/detail/:id', component: ListenDetail, props: true , replace: true},
      { path: '/listens/notfound', component: NotFound },
      { path: '/my/profile', component: UserCenter },
      { path: '/notice/detail/:id', component: NoticeDetail, props: true , replace: true},
    ]
  },
]

// 创建路由实例
const router = new VueRouter({
  mode: 'hash', // 使用 hash 模式
  base: process.env.BASE_URL, // 使用环境变量中的基本 URL
  routes // 路由规则
})

// 全局路由守卫，在每次路由跳转前执行
router.beforeEach((to, from, next) => {
  // 如果页面不是公共页面且用户未登录缓存没有token，则跳转到登录页面
  if (!to.meta.isPublic && !localStorage.token) {
    return next('login')
  }
  // 如果令牌未过期，或者是公共页面，继续跳转
  next();
})

// 导出路由实例
export default router
