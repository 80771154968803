<template>
  <div>
    <!-- PC 端 -->
    <div>
      <el-form inline @submit.native.prevent v-if="!isMobile">
        <el-row>
          <el-col :span="24" class="header-button">
            <el-button type="success" round class="refresh" @click="fetch">刷新</el-button>
            <el-tooltip class="tooltip" effect="light" placement="left-end">
              <div slot="content">1. 获取音源，请前往「小听清」小程序<br />2. 链接有效期，默认为7天</div>
              <el-button class="question" type="primary" icon="el-icon-question" circle></el-button>
            </el-tooltip>
          </el-col>
        </el-row>
      </el-form>
      <el-table v-loading="loading" :data="items" header-align="center" v-if="!isMobile">
        <el-table-column type="index" label="" width="38" align="center">
          <template slot-scope="scope">
            <span :class="{ 'disabled-cell': scope.row.isExpired }" class="ellipsis">{{ scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="title" label="标题">
          <template slot-scope="scope">
            <el-tooltip :content="scope.row.title" effect="light" placement="top">
              <div :class="{ 'disabled-cell': scope.row.isExpired }" class="ellipsis">{{ scope.row.title }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="artist" label="歌手" class="column-artist">
          <template slot-scope="scope">
            <div :class="{ 'disabled-cell': scope.row.isExpired }" class="ellipsis">{{ scope.row.artist }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="album" label="专辑">
          <template slot-scope="scope">
            <div :class="{ 'disabled-cell': scope.row.isExpired }" class="ellipsis">{{ scope.row.album }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="link" label="链接" width="160" align="center">
          <template slot-scope="scope">
            <el-link :href="scope.row.link" type="primary" v-if="!scope.row.isExpired"
              :disabled="scope.row.isExpired">点击打开</el-link>
            <el-link :href="scope.row.link" type="danger" v-if="scope.row.isExpired"
              :disabled="scope.row.isExpired">已失效</el-link>
            <el-tooltip content="复制链接" placement="top" v-if="!scope.row.isExpired">
              <i class="el-icon-document-copy" @click="copyLink(scope.row.link)"
                style="cursor: pointer; margin-left: 10px;" v-if="!scope.row.isExpired">
              </i>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="key" label="密钥" width="160" align="center">
          <template slot-scope="scope">
            <div :class="{ 'disabled-cell': scope.row.isExpired }" class="ellipsis">{{ scope.row.key }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="createdAt" label="获取时间" width="160" align="center" class="column-created-at">
          <template slot-scope="scope">
            <div :class="{ 'disabled-cell': scope.row.isExpired }" class="ellipsis">{{ formatDate(scope.row.createdAt)}}</div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="160" align="center">
          <template slot-scope="scope">
            <div class="ellipsis">
              <el-button type="success" icon="el-icon-view" circle
                @click="$router.push(`/listens/detail/${scope.row._id}`)" v-if="!scope.row.isExpired"
                :disabled="scope.row.isExpired"></el-button>
              <!-- <el-button type="text" size="small" @click="$router.push(`/listens/edit/${scope.row._id}`)">编辑</el-button> -->
              <el-button icon="el-icon-delete" circle @click="remove(scope.row)"></el-button>
            </div>
          </template>
        </el-table-column>
        <!-- 自定义暂无数据提示 -->
        <template slot="empty">
          <div class="custom-empty">
            <!-- 自定义内容 -->
            <p>暂无数据，可以前往「小听清」小程序获取。</p>
          </div>
        </template>
      </el-table>

      <div v-if="!isMobile" style="display: flex; justify-content: center; align-items: center; margin-top: 20px">
        <el-pagination @size-change="fetch()" @current-change="fetch()" background :current-page.sync="query.page"
          :page-sizes="[20, 30, 50]" :page-size.sync="query.perPage" layout="total,sizes, prev, pager, next, jumper"
          :total="total" v-if="!isMobile">
        </el-pagination>
      </div>
    </div>

    <!-- 移动端 -->
    <div>
      <el-form v-if="isMobile" size="small">
        <el-row>
          <el-form-item size="small" class="header-form">
            <el-col :span="24" class="header-button">
              <el-button type="success" round class="refresh" @click="fetch">刷新</el-button>
              <el-tooltip class="tooltip" effect="light" placement="left-end">
                <div slot="content">1. 获取音源，请前往「小听清」小程序<br />2. 链接有效期，默认为7天</div>
                <el-button class="question" type="primary" icon="el-icon-question" circle></el-button>
              </el-tooltip>
            </el-col>
          </el-form-item>
        </el-row>
      </el-form>
      <el-table :data="items" header-align="center" v-if="isMobile" style="font-size: small;" class="table-content">
        <el-table-column type="index" label="" width="38" align="center">
          <template slot-scope="scope">
            <span :class="{ 'disabled-cell': scope.row.isExpired }" class="ellipsis">{{ scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="title" label="标题">
          <template slot-scope="scope">
            <el-tooltip :content="scope.row.title" effect="dark" placement="top">
              <div :class="{ 'disabled-cell': scope.row.isExpired }" class="ellipsis">{{ scope.row.title }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="key" label="密钥" width="55" align="center">
          <template slot-scope="scope">
            <div :class="{ 'disabled-cell': scope.row.isExpired }" class="ellipsis" style="font-size: 13px;">{{ scope.row.key }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="link" label="链接" width="110" align="center">
          <template slot-scope="scope">
            <el-button type="primary" plain round v-if="!scope.row.isExpired" @click="copyLinkButton(scope.row.link)"
              size="small">复制链接</el-button>
            <el-link :href="scope.row.link" type="danger" v-if="scope.row.isExpired"
              :disabled="scope.row.isExpired">已失效</el-link>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="95" align="center">
          <template slot-scope="scope">
            <div class="ellipsis">
              <el-button type="success" icon="el-icon-view" circle size="small"
                @click="$router.push(`/listens/detail/${scope.row._id}`)" v-if="!scope.row.isExpired"
                :disabled="scope.row.isExpired"></el-button>
              <el-button icon="el-icon-delete" circle size="small" @click="remove(scope.row)"></el-button>
            </div>
          </template>
        </el-table-column>
        <!-- 自定义暂无数据提示 -->
        <template slot="empty">
          <div class="custom-empty">
            <!-- 自定义内容 -->
            <p>暂无数据，可以前往「小听清」小程序获取。</p>
          </div>
        </template>
      </el-table>
      <div v-if="isMobile" style="display: flex; justify-content: center; align-items: center; margin-top: 20px">
        <el-pagination @size-change="fetch()" @current-change="fetch()" background :current-page.sync="query.page"
          :page-sizes="[20, 30, 50]" :page-size.sync="query.perPage" layout="total, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </div>

  </div>
</template>

<script>
import Clipboard from 'clipboard';

export default {
  data() {
    return {
      items: [],
      total: 0,
      query: {
        page: 1,
        perPage: 20,
        seach_word: '',
      },
      isMobile: false,
      loading: true,
    }
  },
  methods: {
    seach() {
      this.query.page = 1
      this.fetch()
    },

    async fetch() {
      const res = await this.$http.get('/listenlist', { params: this.query })
      this.items = res.data.items;
      this.total = res.data.total;
      this.loading = false;
      // console.log(this.items)
      // this.items = res.data
      // this.total = res.data.length
    },

    formatDate(date) {
      if (!date) return '';
      const d = new Date(date);
      const year = d.getFullYear();
      const month = (`0${d.getMonth() + 1}`).slice(-2);
      const day = (`0${d.getDate()}`).slice(-2);
      const hours = (`0${d.getHours()}`).slice(-2);
      const minutes = (`0${d.getMinutes()}`).slice(-2);
      const seconds = (`0${d.getSeconds()}`).slice(-2);
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },

    copyLink(link) {
      const clipboard = new Clipboard('.el-icon-document-copy', {
        text: function () {
          return link;
        }
      });
      clipboard.on('success', () => {
        this.$message.success('链接已复制');
        clipboard.destroy();
      });
      clipboard.on('error', () => {
        this.$message.error('复制失败');
        clipboard.destroy();
      });
    },

    copyLinkButton(link) {
      // 使用 Clipboard API 复制链接
      if (navigator.clipboard) {
        navigator.clipboard.writeText(link).then(() => {
          this.$message.success('链接已复制');
        }).catch(err => {
          this.$message.error('复制失败');
          console.error('Failed to copy text: ', err);
        });
      } else {
        // 如果 Clipboard API 不支持，可以使用 fallback 方法
        const textArea = document.createElement("textarea");
        textArea.value = link;
        document.body.appendChild(textArea);
        textArea.select();
        try {
          document.execCommand('copy');
          this.$message.success('链接已复制');
        } catch (err) {
          this.$message.error('复制失败');
          console.error('Fallback: Oops, unable to copy', err);
        }
        document.body.removeChild(textArea);
      }
    },

    async remove(row) {
      this.$confirm(`确定要删除"${row.title}"吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await this.$http.delete(`/listens/delete/${row._id}`)
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
        this.fetch()
      })
    },

    checkIsMobile() {
      this.isMobile = window.innerWidth <= 768;
      // console.log(this.isMobile)
    },

    // 视图效果 禁用已过期的整行
    // getRowClass({ row }) {
    //   return row.isExpired ? 'expired-row' : '';
    // },

  },

  created() {
    this.fetch();
    this.checkIsMobile();
    window.addEventListener('resize', this.checkIsMobile);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkIsMobile);
  }
}
</script>


<style>
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.header-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 30px;
  margin-bottom: 18px;
}

/* .refresh {
  width: 80px;
  align-items: center;
  text-align: center;
} */

/* .refresh, .tooltip{
  margin: 0px 30px;
} */

.question {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  /* 调整按钮的宽度 */
  height: 40px;
  /* 调整按钮的高度 */
  padding: 0;
  /* 移除内边距 */
}

.question .el-icon-question {
  font-size: 25px;
  /* 调整图标的大小 */
}

.custom-empty {
  text-align: center;
  color: #999;
  /* padding: 20px; */
}

.expired-row {
  background-color: #f5f5f5;
}

.expired-row {
  pointer-events: none;
  /* 禁用除删除按钮以外的操作 */
  opacity: 0.6;
  /* 使按钮变得不那么显眼 */
}

.disabled-cell {
  pointer-events: none;
  /* 不允许用户交互 */
  opacity: 0.6;
  /* 使按钮变得不那么显眼 */
}


@media (max-width: 768px) {

  .column-artist,
  .column-created-at {
    display: none;
  }

  .header-form{
    height: 40px;
  }

  .header-button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 10px;
    
  }

  .ellipsis{
    font-size: 13.5px;
  }
  
  .copylink{
    margin: 0px -20px;
  }

  .refresh {
    height: 35px;
    align-items: center;
    text-align: center;
  }

}
</style>