<template>
  <div class="listen-detail-page">
    <!-- PC端 -->
    <el-card class="listen-detail" v-if="!isMobile">
      <el-form ref="modelForm" :model="model" class="form">
        <el-form-item label="" style="text-align: center;">
          <span class="name">{{ model.title }}</span>
        </el-form-item>
        <el-form-item label="" style="text-align: center;">
          <span class="artist">{{ model.artist }}</span>
        </el-form-item>
        <el-form-item label="">
          <span>专辑：{{ model.album }}</span>
        </el-form-item>
        <el-form-item label="">
          <div class="link">
            <span>链接：</span>
            <el-link type="primary" :href="model.link">点击打开</el-link>
            <el-tooltip content="复制链接" placement="top">
              <i class="el-icon-document-copy" @click="copyLink(model.link)"
                style="cursor: pointer; margin-left: 10px;">
              </i>
            </el-tooltip>
          </div>
        </el-form-item>
        <el-form-item label="">
          <span>密钥：{{ model.key }}</span>
        </el-form-item>
        <el-form-item label="">
          <span>获取时间：{{ formatDate(model.createdAt)}}</span>
        </el-form-item>
        <!-- 可选：增加一个返回按钮 -->
        <el-row class="button-group">
          <el-button @click="cancel" type="primary">返回</el-button>
        </el-row>
      </el-form>
    </el-card>

    <!-- 移动端 -->
    <el-card class="listen-detail" v-if="isMobile">
      <el-form ref="modelForm" :model="model" class="form">
        <el-form-item label="" style="text-align: center;">
          <span class="name">{{ model.title }}</span>
        </el-form-item>
        <el-form-item label="" style="text-align: center;">
          <span class="artist">{{ model.artist }}</span>
        </el-form-item>
        <el-form-item label="">
          <span>专辑：{{ model.album }}</span>
        </el-form-item>
        <el-form-item label="">
          <div class="link">
            <span>链接：</span>
            <el-button type="primary" plain round @click="copyLinkButton(model.link)" size="small">复制链接</el-button>
          </div>
        </el-form-item>
        <el-form-item label="">
          <span>密钥：{{ model.key }}</span>
        </el-form-item>
        <el-form-item label="">
          <span>获取时间：{{ formatDate(model.createdAt)}}</span>
        </el-form-item>
        <!-- 可选：增加一个返回按钮 -->
        <el-row class="button-group">
          <el-button @click="cancel" type="primary">返回</el-button>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<style scoped>
.listen-detail-page {
  margin: 20px;
  text-align: center;
}

.form {
  margin: 20px;
}

.name {
  text-align: center;
  font-size: 30px;
}

.artist {
  text-align: center;
  font-size: 15px;
}

.copy {
  margin-left: 10px;
}


.button-group {
  text-align: center;
  margin-top: 20px;
}
</style>

<script>
import Clipboard from 'clipboard';

export default {
  props: {
    id: {}
  },
  data() {
    return {
      model: {
        unionid: '',
        title: '',
        artist: '',
        album: '',
        link: '',
        source: '',
        key: '',
        createdAt: '',
      },
      isMobile: false,
    }
  },
  methods: {

    async fetch() {
      try {
        const res = await this.$http.get(`listens/detail/${this.id}`);
        this.model = res.data;
      } catch (error) {
        if (error.response) {
          this.$router.push('/listens/notfound');
        } else {
          console.error(error);
        }
      }
    },

    formatDate(date) {
      if (!date) return '';
      const d = new Date(date);
      const year = d.getFullYear();
      const month = (`0${d.getMonth() + 1}`).slice(-2);
      const day = (`0${d.getDate()}`).slice(-2);
      const hours = (`0${d.getHours()}`).slice(-2);
      const minutes = (`0${d.getMinutes()}`).slice(-2);
      const seconds = (`0${d.getSeconds()}`).slice(-2);
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },

    async cancel() {
      this.$router.push('/listens/list');
    },

    copyLink(link) {
      const clipboard = new Clipboard('.el-icon-document-copy', {
        text: function () {
          return link;
        }
      });
      clipboard.on('success', () => {
        this.$message.success('链接已复制');
        clipboard.destroy();
      });
      clipboard.on('error', () => {
        this.$message.error('复制失败');
        clipboard.destroy();
      });
    },

    copyLinkButton(link) {
      // 使用 Clipboard API 复制链接
      if (navigator.clipboard) {
        navigator.clipboard.writeText(link).then(() => {
          this.$message.success('链接已复制');
        }).catch(err => {
          this.$message.error('复制失败');
          console.error('Failed to copy text: ', err);
        });
      } else {
        // 如果 Clipboard API 不支持，可以使用 fallback 方法
        const textArea = document.createElement("textarea");
        textArea.value = link;
        document.body.appendChild(textArea);
        textArea.select();
        try {
          document.execCommand('copy');
          this.$message.success('链接已复制');
        } catch (err) {
          this.$message.error('复制失败');
          console.error('Fallback: Oops, unable to copy', err);
        }
        document.body.removeChild(textArea);
      }
    },


    checkIsMobile() {
      this.isMobile = window.innerWidth <= 768;
      // console.log(this.isMobile)
    },

  },
  created() {
    this.id && this.fetch();
    this.checkIsMobile();
    window.addEventListener('resize', this.checkIsMobile);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.checkIsMobile);
  },
}
</script>
