<template>
  <div class="notice-detail">
    <el-card class="box-card">
      <h2 class="title">{{ model.title }}</h2>
      <div class="info">
        <p class="author"> {{ model.author }}</p>
        <p class="date"> {{ formatDate(model.createdAt) }}</p>
      </div>
      <el-divider></el-divider>
      <div class="ql-editor">
        <p v-html="model.content"></p>
      </div>
    </el-card>
  </div>
</template>


<script>
export default {
  props: {
    id: {}
  },
  data() {
    return {
      model: {
        title: '',
        author: '',
        content: '',
        createdAt: '',
        sites: [],
      },
    };
  },

  methods: {
    async fetch() {
      const res = await this.$http.get(`rest/notices/${this.id}`)
      this.model = res.data
    },

    formatDate(date) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(date).toLocaleDateString('zh-CN', options);
    },
  },

  created() {
    this.fetch()
  },
};
</script>


<style scoped>
.notice-detail {
  padding: 0px 10px;
}

.title {
  text-align: center;
  margin-bottom: 10px;
}

.box-card {
  margin-top: 20px;
}

.info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  margin: 10px 30px;
}


.ql-editor {
  margin: 0px 20px;
}

.author,
.date {
  margin: 0;
}
</style>
